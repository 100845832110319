<template>
  <main class="w-100 selectJourney p-3 px-4 container">
    <section class="w-100 d-flex flex-column align-items-center mt-5">
      <h3 class="text-neutral-light font-weight-300 text-center">
        Please choose how you want to build your system
      </h3>
      <div
        class="w-100 mt-5 d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start gap-5"
      >
        <div
          class="d-flex flex-column wd-80p wd-md-30p cursor-pointer"
          @click="goToDashboard"
        >
          <square-container>
            <div
              class="d-flex flex-column p-3 justify-content-around align-items-center bg-neutral h-100 w-100"
            >
              <img
                class="text-primary"
                width="85"
                alt="Room by room customization"
                :src="require('../../assets/images/room-customization.png')"
              />
              <h5 class="text-neutral-dark text-center m-0 mt-2">
                Room by room customization
              </h5>
            </div>
          </square-container>
          <h5
            class="text-neutral-light font-weight-400 mt-3 mx-auto text-center"
          >
            Take me to Dashboard
          </h5>
        </div>
        <div
          class="d-flex flex-column wd-80p wd-md-30p cursor-pointer"
          @click="goToWizard"
        >
          <square-container>
            <div
              class="d-flex flex-column p-3 justify-content-around align-items-center bg-neutral h-100 w-100"
            >
              <inline-svg
                class="wd-90p text-primary"
                :src="require('../../assets/space7_logo.svg')"
              />
              <h5 class="text-neutral-dark m-0">Wizard</h5>
            </div>
          </square-container>
          <h5
            class="text-neutral-light font-weight-400 mt-3 mx-auto text-center"
          >
            Allow Space 7 to recommend a system
          </h5>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SquareContainer from "../../components/SquareContainer";
import InlineSvg from "vue-inline-svg";

export default {
  name: "SelectJourney",
  components: { SquareContainer, InlineSvg },
  methods: {
    goToDashboard() {
      this.$store.commit("project/setIsWizard", false);
      this.$router.push({ name: "dashboard" });
    },
    goToWizard() {
      this.$store.commit("project/setIsWizard", true);
      this.$router.push({ name: "wizard" });
    },
  },
};
</script>

<style scoped lang="scss">
.selectJourney {
  &__room-icon {
    fill: var(--text-color);
    border: 2px solid var(--border-color);
    border-radius: 50%;
    width: 60%;
    padding: 12.5%;
    overflow: visible;
  }
}
</style>
